import React, { Fragment } from 'react'
import { graphql } from 'gatsby'
import styled, { css } from 'styled-components'
import { up } from '../lib/styles'
import {
  Row,
  Col,
  Gap,
  SEO,
  Title,
  Container,
  Button,
  Link,
  ArticleThumbnail,
} from '../components'
import { extractKeystoneData } from '../lib/helpers'

const Filter = styled.div`
  ${({ theme: { fonts, colors }, isActive }) => css`
    min-height: 40px;
    border-radius: 28px;
    background-color: ${colors.backgroundBlue};
    padding: 10px 20px;
    opacity: 0.75;
    color: ${colors.lightBlue};
    font-family: ${fonts.venti};
    font-size: 14px;
    font-weight: 700;
    letter-spacing: 3.5px;
    line-height: 20px;
    text-transform: uppercase;
    cursor: pointer;
    transition: 0.2s;

    margin: 5px 10px;

    &:hover {
      color: ${!isActive && colors.darkBlue};
    }

    ${isActive
      && css`
        background-color: ${colors.lightBlue};
        color: white;
      `}
  `}
`

const Grid = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-column-gap: 30px;
  grid-row-gap: 60px;
  width: 100%;

  ${up('mobile')} {
    grid-template-columns: repeat(2, 1fr);
  }
  ${up('tablet')} {
    grid-template-columns: repeat(3, 1fr);
  }
  ${up('desktop')} {
    grid-template-columns: repeat(4, 1fr);
  }
`

const getCategories = articles =>
  articles
  && articles.reduce((result, article) => {
    const { categories } = article
    categories.forEach(category => {
      if (category.isFilter && !result.some(({ id }) => id === category.id)) {
        result.push({ ...category })
      }
    })
    return result
  }, [])

const LAZY_RENDER_STEP = 16

class Page extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      activeFilterIndex: -1,
      articlesLimit: LAZY_RENDER_STEP,
    }
  }

  showMore = () => {
    this.setState(({ articlesLimit }) => ({
      articlesLimit: articlesLimit + LAZY_RENDER_STEP,
    }))
  }

  setFilter = activeFilterIndex => {
    this.setState({ activeFilterIndex, articlesLimit: LAZY_RENDER_STEP })
  }

  render() {
    const {
      location: { pathname },
      data,
    } = this.props
    const { activeFilterIndex, articlesLimit } = this.state
    const { allArticle } = extractKeystoneData(data)
    const categories = getCategories(allArticle)
    let filteredArticles = allArticle

    if (activeFilterIndex !== -1) {
      const activeCategoryId = categories[activeFilterIndex].id
      filteredArticles = allArticle.filter(({ categories }) =>
        categories.some(({ id }) => id === activeCategoryId))
    }

    const showedArticles = filteredArticles.slice(0, articlesLimit)

    return (
      <>
        <SEO pathname={pathname} title="Články" />
        <Gap.Top />
        <Container>
          <Col width="100%" justifyContent="center" alignItems="center">
            <Title.Main>Články</Title.Main>
          </Col>
          <Gap gap="185px" />
          <Row margin="auto" justifyContent="center" wrap="wrap">
            <Filter
              isActive={activeFilterIndex === -1}
              onClick={() => this.setFilter(-1)}
            >
              vše
            </Filter>
            {categories
              && categories.map(({ name }, key) => (
                <Fragment key={key}>
                  <Filter
                    isActive={activeFilterIndex === key}
                    onClick={() => this.setFilter(key)}
                  >
                    {name}
                  </Filter>
                </Fragment>
              ))}
          </Row>

          <Gap gap="100px" />

          <Col width="100%" alignItems="center">
            <Grid>
              {filteredArticles
                && showedArticles.map(({ id, title, slug, image }) => (
                  <Link to={`/${slug}`} key={id}>
                    <ArticleThumbnail title={title} image={image} />
                  </Link>
                ))}
            </Grid>
          </Col>
          <Gap gap="80px" />
          {filteredArticles.length > articlesLimit && (
            <Col width="100%" alignItems="center">
              <Button.Simple onClick={this.showMore}>
                UKÁZAT DALŠÍ ČLÁNKY
              </Button.Simple>
            </Col>
          )}
          <Gap gap="180px" />
        </Container>
      </>
    )
  }
}

export default Page

export const pageQuery = graphql`
  query {
    allArticle(
      sort: { order: ASC, fields: sortOrder }
      filter: { isPublished: { eq: true } }
    ) {
      nodes {
        ...ArticleFragment
      }
    }
  }
`
